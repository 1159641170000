/** @format */

import { CUSTOM_ELEMENTS_SCHEMA, NgModule } from "@angular/core"

import { AdminLayoutsModule } from "./layouts/index"
import { AdminLandingModule } from "./landing/admin_landing.module"
import { AdminUsuariosModule } from "./usuarios/admin_usuarios.module"
import { AdminBannersModule } from "./artezonas/admin_artezonas.module"
import { AdminNominasModule } from "./carga_nominas/admin_nominas.module"
import { AdminPlanEstudiosModule } from "./plan_estudios/admin_plan_estudios.module"
import { AdminInstrumentosModule } from "./instrumentos/admin_instrumentos.module"
import { AdminEstablecimientosModule } from "./establecimientos/admin_establecimientos.module"
import { AdminAsociacionesModule } from "./asociaciones/admin_asociaciones.module"
import { AdminCuentasModule } from "./cuentas/admin_cuentas.module"
import { AdminNoticiasModule } from "./noticias/admin_noticias.module"
import { AdminStreamingModule } from "./streaming/admin_streaming.module"
import { AdminEstadisticasModule } from "./estadisticas/admin_estadisticas.module"
import { AdminEvaluacionesModule } from "./evaluaciones/admin_evaluaciones.module"
import { AdminBlogModule } from "./blog/admin_blog.module"
import { AdminPlanificacionesModule } from "./planificaciones/admin-planificaciones.module"
import { AdminAsignaturaEvaluacionTiposModule } from "./asignatura-evaluacion-tipos/asignatura-evaluacion-tipos.module"
import { AdminAchievementsModule } from "./achievements/admin-achievements.module"
import { AdminTypeformWidgetsModule } from "./typeform-widgets/admin-typeform-widgets.module"
import { config } from "../config/config"
import { environment } from "../environments/environment"
import { AppComponent, getProviders, PuntajeCoreModule } from "@puntaje/puntaje/core"
import { AdminAyudasModule } from "./ayudas/admin-ayudas.module"
import { AdminEscalaGlobalesModule } from "./escala-globales/escala-globales.module"

@NgModule({
    imports: [
        AdminLayoutsModule,
        AdminLandingModule,
        AdminUsuariosModule,
        AdminBannersModule,
        AdminNominasModule,
        AdminPlanEstudiosModule,
        AdminInstrumentosModule,
        AdminEstablecimientosModule,
        AdminAsociacionesModule,
        AdminCuentasModule,
        AdminNoticiasModule,
        AdminStreamingModule,
        AdminEstadisticasModule,
        AdminEvaluacionesModule,
        AdminBlogModule,
        AdminPlanificacionesModule,
        AdminAsignaturaEvaluacionTiposModule,
        AdminAchievementsModule,
        AdminTypeformWidgetsModule,
        AdminAyudasModule,
        AdminEscalaGlobalesModule,
        PuntajeCoreModule
    ],
    schemas: [CUSTOM_ELEMENTS_SCHEMA],
    bootstrap: [AppComponent],
    providers: getProviders(config, environment)
})
export class AppModule {}
